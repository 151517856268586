// eslint-disable-next-line import/prefer-default-export

export const productsWithLinks = [
  {
    title: `SpotOn \nReserve`,
    linkTo: "https://developers.spoton.com/reserve",
    imageName: "spoton-reserve.png",
  },
  {
    title: `SpotOn \nRestaurant`,
    linkTo: "https://developers.spoton.com/restaurant",
    imageName: "spoton-restaurant.png",
  },
  {
    title: `SpotOn \nEnterprise`,
    linkTo: "https://developers.spoton.com/enterprise",
    imageName: "spoton-enterprise.png",
  },
];

export const titleDeveloperCenter = "Developer center";

export const subtitleDeveloperCenter =
  "Get the resources you need to successfully integrate with SpotOn.";

export const developerCenterSubverticals = {
  title: "",
  noMore: true,
  subverticalInfo: [
    {
      target: "/products/reserve",
      iconImg: "reservation.png",
      subverticalName: "Reserve",
      subverticalContent:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus magna augue, ac egestas mi malesuada eget. Sed at vehicula risus.",
      links: [{ linkTitle: "Learn more", linkTarget: "/products/reserve" }],
    },
    {
      target: "/restaurant-pos",
      iconImg: "restaurant.png",
      subverticalName: "Restaurant",
      subverticalContent:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus magna augue, ac egestas mi malesuada eget. Sed at vehicula risus.",
      links: [{ linkTitle: "Learn more", linkTarget: "/restaurant-pos" }],
    },
    {
      target: "/enterprise",
      iconImg: "enterprise.png",
      subverticalName: "Enterprise",
      subverticalContent:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus magna augue, ac egestas mi malesuada eget. Sed at vehicula risus.",
      links: [{ linkTitle: "Learn more", linkTarget: "/enterprise" }],
    },
  ],
};

export const integrationV2 = {
  title: "Designed to integrate with the software you love",
  subTitle:
    "We don’t make you work around our technology. Our flexible solutions are designed to integrate with other software and systems so it works for you—not the other way around.",
  partnerTitle: "Become an integration partner of SpotOn",
  partnerSubTitle:
    "SpotOn's Integration Partner Program enables partners to scale their integrations while providing our mutual customers more robust solutions to grow their business!",
  partnerBackgroundImageName: "",
  partnerBackgroundMobileImageName: "",
  partnerCta: {
    title: "Request an integration",
    target: "/partners/become-a-partner",
  },
};

export const footer = {
  title: "We never stop looking for talented people",
  subtext: "See our exciting career opportunities and apply today.",
  label: "",
  ctaPrimary: {
    title: "See open positions",
    target: "/careers",
  },
};
