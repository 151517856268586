import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import Heading from "../../components-v2/Base/HeadingBuilder";
import Section from "../../components-v2/Base/Section";
import ProductCardBlueCircle from "../../components-v2/Base/ProductCardBlueCircle";
import {
  footer,
  titleDeveloperCenter,
  subtitleDeveloperCenter,
  integrationV2,
  productsWithLinks,
} from "../../data/developer-center-data";

import ogImage from "../../images/global_assets/og-image.png";
import integrations from "../../components-v2/IntegrationsOverview/data/restaurant-integrations";

const IntegrationsOverview = loadable(() =>
  import("../../components-v2/IntegrationsOverview")
);

const FooterHero = loadable(() =>
  import("../../components-v2/Sections/Hero/FooterHero")
);

const DeveloperCenter = () => {
  return (
    <Layout className="restaurants">
      <SEO
        title="Restaurant POS | All-In-One Software For Restaurants | SpotOn"
        description="The tools and support you need to successfully integrate with SpotOn"
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/developer-center"
        productSchemaSR
      />

      <main>
        <Section className="mt-20 mb-10 md:mb-[7.5rem] md:mt-32 z-8">
          <Heading
            level={1}
            injectionType={1}
            withBlueSymbol
            className="max-w-[645px] mx-auto text-center"
          >
            {titleDeveloperCenter}
          </Heading>

          <p className="text-black-300 text-p-large text-center">
            {subtitleDeveloperCenter}
          </p>

          <div
            className={`
          mt-10 md:mt-[100px]
          flex flex-col md:flex-row flex-wrap
          justify-center items-center md:items-start
          gap-10 xl:gap-20
        `}
          >
            <ProductCardBlueCircle
              {...productsWithLinks[0]}
              className="whitespace-pre-line"
              openInNewTab
            />
            <ProductCardBlueCircle
              className="xl:mt-[-50px] whitespace-pre-line"
              {...productsWithLinks[1]}
              openInNewTab
            />
            <ProductCardBlueCircle
              {...productsWithLinks[2]}
              className="whitespace-pre-line"
              openInNewTab
            />
          </div>
        </Section>

        <IntegrationsOverview
          className=""
          title={integrationV2.title}
          subTitle={integrationV2.subTitle}
          integrations={integrations}
        />
      </main>
      <FooterHero
        className="mt-20 lg:mt-36 mb-12 lg:mb-20"
        {...footer}
        variant={2}
      />
    </Layout>
  );
};

export default DeveloperCenter;
